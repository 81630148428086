import { useEffect, useState } from "react";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { useProductSearch } from "@ludens-reklame/rubics-v3-react/product/hooks";
import { Page } from "@ludens-reklame/rubics-v3-sdk/page/types";
import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { CourseCard } from "./courseCard.js";
import { ChipList, ChipSelect } from "../../internal-components/chip/chip.js";
import { MultiSelect } from "../../internal-components/multiSelect/multiSelect.js";
import { arrow } from "../../icons/svg.js";

interface Props {
  title?: string;
  category?: Page;
  tags?: string[];
  limit?: number;
  link?: Link;
}

const CourseFilter: React.FC<Props> = ({
  title,
  category,
  tags = [],
  limit,
  link,
}) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const page = useStore().context.page;
  const { results, hasMore, search } = useProductSearch();

  useEffect(() => {
    search(false, {
      tags: selectedTags,
      categories:
        category?._id || page._id ? [category?._id || page._id] : undefined,
      faceted: false,
      limit: limit || 20,
      "sort.publishedDate": -1,
    });
  }, [selectedTags, category?._id, page._id]);

  return (
    <div className="course-filter vs-xxl hs mw">
      {title && <h2 className="h1">{title}</h2>}
      {tags.length > 0 && (
        <div className="vs-l">
          {tags.length > 4 ? (
            <MultiSelect
              placeholder="Filtrer på emneknagger"
              selected={selectedTags.map((t) => ({ value: t, label: t }))}
              options={tags.map((t) => ({ value: t, label: t }))}
              onChange={(selected) =>
                setSelectedTags(selected.map((s) => s.value))
              }
            />
          ) : (
            <ChipList>
              {tags.map((t) => (
                <ChipSelect
                  label={t}
                  isSelected={selectedTags.includes(t)}
                  onSelect={(e) => {
                    if (e.target.checked) {
                      if (!selectedTags.includes(t)) {
                        setSelectedTags([...selectedTags, t]);
                      }
                    } else {
                      setSelectedTags(selectedTags.filter((st) => st !== t));
                    }
                  }}
                />
              ))}
            </ChipList>
          )}
        </div>
      )}
      <div className="list">
        {results.map((p) => (
          <CourseCard key={p._id} course={p} />
        ))}
      </div>
      {hasMore && !link && (
        <div className="vs-xl h-center">
          <button onClick={() => search(true)}>Last inn flere</button>
        </div>
      )}
      {link && (
        <div className="vs-xl h-center">
          <a className="button" href={link.url} aria-label={link.ariaLabel}>
            {link.title} {arrow}
          </a>
        </div>
      )}
    </div>
  );
};

export default CourseFilter;
