import { useMemo } from "react";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { Product } from "@ludens-reklame/rubics-v3-sdk/product/types";
import { arrow } from "../../icons/svg.js";
import { LinkList } from "../../internal-components/linkList/linkList.js";
import {
  ChipDate,
  ChipInfo,
  ChipList,
} from "../../internal-components/chip/chip.js";
import { RichText } from "../../internal-components/richText/RichText.js";

interface Props {
  course: Product;
}

export const CourseCard: React.FC<Props> = ({ course }) => {
  const image = course.primaryImage;
  const primaryCategory = course.primaryCategory;
  const tagList = course.tags.length > 0 && (
    <ChipList>
      {course.tags.map((tag, key) => (
        <ChipInfo
          key={tag}
          label={tag}
          variant={key % 2 === 0 ? "tertiary" : "quaternary"}
        />
      ))}
    </ChipList>
  );

  const courseDates = useMemo<{ date: string; url: string }[]>(() => {
    const attr = course.attributes.find((a) => a.key === "Dato");

    if (attr) {
      return [
        { date: attr.value, url: course.url },
        ...course.variants
          .filter((v) => !!v.attributeValues.Dato)
          .map((v) => ({
            date: v.attributeValues.Dato,
            url: v.url,
          })),
      ];
    }

    return [];
  }, [course]);

  return (
    <div className="course-card-wrapper">
      <div className="course-card">
        {image && (
          <img
            src={transformImageUrl(image, { width: 600 })}
            alt={image.altText}
            loading="lazy"
          />
        )}
        <div className="content">
          {tagList && <div className="small">{tagList}</div>}
          <div className="top-line">
            {primaryCategory && (
              <LinkList>
                <a href={primaryCategory.url}>{primaryCategory.title}</a>
              </LinkList>
            )}
            {courseDates.length > 0 && (
              <div className="dates">
                <ChipList>
                  {courseDates.map((d) => (
                    <a href={d.url}>
                      <ChipDate key={d.date} label={d.date} />
                    </a>
                  ))}
                </ChipList>
              </div>
            )}
          </div>
          <p className="h4 title">{course.name}</p>
          {course.description && <RichText html={course.description} />}
          <div className="bottom-line">
            {tagList && <div className="large">{tagList}</div>}
            <a href={course.url} className="button secondary">
              Les mer {arrow}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
